#splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  /* temporary background */
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  opacity: 1;
}

.max #splash {
  background-image: url('../assets/max.jpg');
}

.hbomax #splash {
  background-image: url('../assets/hbomax.jpg');
}

@media (max-width: 1280px) {
  #splash::after {
    top: 312px;
    left: 565px;
  }
}

/* hide background */
#splash.hide {
  opacity: 0;
  transition: opacity 600ms ease-in-out;
}

#spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 70%;
  margin-left: -50px;
  opacity: 1;
  background-image: url('../assets/spinner-blue.gif');
  background-repeat: no-repeat;
}

/* hide spinner */
#spinner.hide {
  opacity: 0;
  transition: opacity 600ms ease-in-out;
}

#splash-video-container {
  position: absolute;
}

#a11y-button {
  opacity: 0;
  position: absolute;
  top: -1px;
  left: -1px;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #000000;
}

body > canvas {
  position: absolute;
}

body > input {
  position: absolute;
  left: -1000px;
}

#a11y-button {
  opacity: 0;
  position: absolute;
  top: -1px;
  left: -1px;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}
